<template>
<keep-alive><mail /></keep-alive>
    
</template>

<script>
// @ is an alias to /src
import mail from "@/components/mail";
export default {
  name: "In_mail",
  components: {
    mail,
  }
};
</script>