<template>
  <div class="app-page">
    <Topbar1></Topbar1>
    <el-dialog title="通知详情" :visible.sync="dialogshow" width="40%" :before-close="handleClose">
      <p v-html="row_value.content"></p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogshow = false">确 定</el-button>
      </span>
    </el-dialog>

    <div class="bg">
      <div class="center_body">
        <el-tabs :tab-position="tabPosition" v-model="activename" @tab-click="handleClick">
          <el-tab-pane label="站内信" name="mail" disabled="disabled">
            <p class="batch_recharge"><span @click="activename = 'person_notice'">平台通知</span> <i>>></i> 详情 </p>
            <div class="top">
              <span @click="setimg()" class="d_title">{{ row_value.title }}</span>
              <span class="d_time">{{ row_value.ctime }}</span>
            </div>
            <div v-html="row_value.content" class="d_content"></div>
          </el-tab-pane>
          <!-- <el-tab-pane label="平台通知"  name="all_notice" >
      <p class="title">平台通知</p>
    <div class="body" style="margin-top:57px;">
            <el-table
    ref="multipleTable"
    :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
    tooltip-effect="dark"
    style="width: 100%"
    >
    <el-table-column
      label="主题"
      width="370"
      show-overflow-tooltip>
      <template slot-scope="scope"><span style="cursor:pointer" @click="detail(scope.row)">{{ scope.row.title}}</span> </template>
</el-table-column>
<el-table-column width="250" show-overflow-tooltip>
</el-table-column>
<el-table-column prop="ctime" label="时间" width="270" show-overflow-tooltip>
</el-table-column>
</el-table>
<el-pagination class="fy" layout="total , prev, pager, next, jumper" prev-text='上一页' next-text='下一页'
  @current-change="current_change" :total="total" :page-size="pagesize" background>
</el-pagination>
</div>
</el-tab-pane> -->
          <el-tab-pane label="平台通知" name="person_notice">
            <p class="title">平台通知</p>
            <div class="top"><label class="lab" for="select">站内信状态</label> <el-select style="width:90px"
                v-model="notice_state" clearable placeholder="状态" name="select" @change="getlist()">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option></el-select>
            </div>
            <div class="body">
              <el-button type="danger" style="float:left;margin-bottom:20px" @click="batch_deal()">批量删除</el-button>
              <el-button type="primary" style="float:right;margin-bottom:20px;" @click="read_all()">全部已读</el-button>
              <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column lable="全部" type="selection" width="55">
                </el-table-column>
                <el-table-column label="信件状态" width="130" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="scope.row.is_read == '2'" class="readed" @click="p_detail(scope.row)"
                      v-loading.fullscreen.lock="fullscreenLoading">已读</span>
                    <span v-else-if="scope.row.is_read == '1'" class="unread" @click="p_detail(scope.row)"
                      v-loading.fullscreen.lock="fullscreenLoading">未读</span>
                  </template>
                </el-table-column>
                <el-table-column label="主题" width="360" show-overflow-tooltip>
                  <template slot-scope="scope"><span class="theme" @click="p_detail(scope.row)"
                      v-loading.fullscreen.lock="fullscreenLoading">{{ scope.row.title }}（{{
                        scope.row.cate_name }}）</span> </template>
                </el-table-column>
                <el-table-column label="时间" width="230" show-overflow-tooltip>
                  <template slot-scope="scope"><span class="theme" @click="p_detail(scope.row)"
                      v-loading.fullscreen.lock="fullscreenLoading">{{ scope.row.ctime }}</span> </template>
                </el-table-column>
                <el-table-column label="操作" width="160" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="unread" @click="deal_mail(scope.row)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination class="fy" layout="total , prev, pager, next, jumper" prev-text='上一页' next-text='下一页'
                @current-change="current_change" :total="total" :page-size="pagesize" background>
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Topbar1 from '@/components/topbar1_4'
export default {
  name: "help-center",
  // inject:['app'],
  components: {
    Topbar1
  },
  data() {
    return {
      dialogshow: false,
      tabPosition: "left",
      activename: "person_notice",
      tableData: [],
      row_value: '',//信息数值
      total: 1,//默认数据总数
      pagesize: 6,//每页的数据条数
      currentPage: 1,//默认开始页面
      notice_state: '',
      options: [{
        value: '',
        label: '全部'
      }, {
        value: '2',
        label: '已读'
      }, {
        value: '1',
        label: '未读'
      }
      ],
      pages: '1',
      suppliershow: false,
      agentshow: false,
      fullscreenLoading: false,
      checked_Select: '',
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
    var id = this.$store.state.user.user_type;
    if (id == 1 || id == 3) {
      this.agentshow = true;
    }
    if (id == 2 || id == 3 || id == 5) {
      this.suppliershow = true;
    }
  },
  activated() {
    this.getlist();
    this.activename = 'person_notice';
  },
  methods: {
    handleClick(tab, event) {
      var that = this;
      // that.id=parseInt(tab.name)+1;
      // let params={'class_id':that.id};
      //   $.ajax({
      //     async:false,
      //     type:'post',
      //     url:'/route/index.php?s=/api/Helps/getcontent',
      //     data:params,
      //     dataType:'json',
      //     success:function(res){
      //        that.content=res.data;
      //     },
      //     error : function(res) {
      //       alert('查询错误');
      //       }
      //   })
    },
    handleClose(done) {
      done();
    },
    handleSelectionChange(val) {
      this.checked_Select = val;
    },
    detail(row) {
      this.dialogshow = true;
      this.row_value = row;
    },
    p_detail(row) {
      var that = this;
      // this.dialogshow=true;
      this.activename = "mail";
      this.row_value = row;
      let params = { 'id': row.id }
      $.ajax({
        async: false,
        type: 'post',
        data: params,
        url: this.$store.state.user.z_url + '/index.php?s=/api/Mail/setread',
        success: function (res) {
        },
      });
      this.loading();
    },
    deal_mail(row) {
      var that = this;
      let params = { 'ids': row.id }
      $.ajax({
        async: false,
        type: 'post',
        data: params,
        url: this.$store.state.user.z_url + '/index.php?s=/api/mail/delmessage',
        success: function (res) {
          that.getlist();
          this.currentPage = this.pages;
        },
      });
    },
    batch_deal() {
      var that = this;
      let checked = this.checked_Select;
      let check2 = [];
      for (let i in checked) {
        check2.push(checked[i].id)
      }
      check2 = check2.join(",")
      if (checked.length != 0) {
        let params = { 'ids': check2 };
        //console.log(params);
        $.ajax({
          async: false,
          type: 'post',
          url: this.$store.state.user.z_url + '/index.php?s=/api/mail/delmanymails',
          data: params,
          dataType: 'json',
          success: function (res) {
            if (res.code == 0) {
              that.$message({
                message: "批量删除成功",
                type: 'success',
                center: 'true',
              });
              that.getlist();
            } else {
              that.$message({
                message: "批量删除失败",
                type: 'error',
                center: 'true',
              });
            }
          },
        });
      } else {
        that.$message({
          message: "请先勾选要删除的信息",
          type: 'error',
          center: 'true',
          // offset: '60',
        });
      }
    },//批量设置
    read_all() {
      var that = this;
      var ids = '';
      var type = '';
      var is_chengmei = '';
      switch (parseInt(this.$store.state.user.user_type)) {
        case 0:
          ids = this.$store.state.user.p_id;
          type = 3;
          is_chengmei = 2;
          break
        case 1:
          ids = this.$store.state.user.p_id;
          type = 1;
          is_chengmei = 2;
          break
        case 2:
          ids = this.$store.state.user.p_id;
          type = 2;
          is_chengmei = 2;
          break
        case 3:
          ids = this.$store.state.user.p_id;
          is_chengmei = 2;
          break
        case 4:
          ids = this.$store.state.user.u_id;
          type = 3;
          is_chengmei = 1;
          break
        case 5:
          ids = this.$store.state.user.u_id;
          type = 2;
          is_chengmei = 1;
          break
      }
      let params = { 'user_id': ids, 'type': type, 'is_chengmei': is_chengmei }
      $.ajax({
        async: false,
        type: 'post',
        data: params,
        url: this.$store.state.user.z_url + '/index.php?s=/api/mail/readall',
        success: function (res) {
          that.getlist();
          this.currentPage = this.pages;
        },
      });
    },
    getlist() {
      var that = this;
      var ids = '';
      var type = '';
      var is_chengmei = '';
      switch (parseInt(this.$store.state.user.user_type)) {
        case 0:
          ids = this.$store.state.user.p_id;
          type = 3;
          is_chengmei = 2;
          break
        case 1:
          ids = this.$store.state.user.p_id;
          type = 1;
          is_chengmei = 2;
          break
        case 2:
          ids = this.$store.state.user.p_id;
          type = 2;
          is_chengmei = 2;
          break
        case 3:
          ids = this.$store.state.user.p_id;
          is_chengmei = 2;
          break
        case 4:
          ids = this.$store.state.user.u_id;
          type = 3;
          is_chengmei = 1;
          break
        case 5:
          ids = this.$store.state.user.u_id;
          type = 2;
          is_chengmei = 1;
          break
      }
      let params = { 'ids': ids, 'type': type, 'is_chengmei': is_chengmei, 'status': this.notice_state, page: this.pages }
      $.ajax({
        async: false,
        type: 'post',
        data: params,
        url: this.$store.state.user.z_url + '/api/mail/getmileinfo',
        success: function (res) {
          var arr = []
          res = JSON.parse(res)
          console.log(res,"ressssssssssss");
          that.tableData=res.data.data
          that.total = res.data.total;
          // delete res.data.count;
          // for (let i in res.data.data) {
          //   if (res.data.data[i].is_read == 1) {
          //     res.data.data[i].is_read = "未读";
          //   } else if (res.data[i].is_read == 2) {
          //     res.data.data[i].is_read = "已读";
          //   }
          //   arr.push(res.data.data[i])
          // }
          // that.tableData = arr;
        },
      });
    },
    setimg() {
      var imgs = document.querySelectorAll(".d_content img");
      for (var i = 0, len = imgs.length; i < len; i++) {
        imgs[i].style = 'width:920px'
      }
    },
    loading() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 600);
    },
    current_change: function (currentPage) {
      this.pages = currentPage
      this.currentPage = this.pages;
      this.getlist()
    },
  },
  watch: {
    activename(val) {
      this.getlist();
      setTimeout(this.setimg, 500);

    }
  }
};
</script>

<style lang="less" scoped>
.app-page {
  position: relative;
}

.bg {
  width: 100%;
  height: 850px;
  background-color: rgba(242, 243, 250, 1);
  position: absolute;
  top: 80px;

  .center_body {
    width: 1250px;
    height: 96%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
  }

  .center_body /deep/ .el-tabs__nav {
    height: 100%;
  }

  .center_body /deep/ .el-tabs__nav-scroll {
    height: 100%
  }

  .center_body /deep/ .el-tabs__nav-wrap {
    height: 100%;
  }

  .center_body /deep/ .el-tabs__header {
    height: 90%;
  }

  .center_body /deep/ .el-tabs--left {
    height: 100%;
    background-color: #fff;
  }

  .center_body /deep/ .el-tabs__item {
    width: 200px;
    height: 60px;
    font-size: 16px;
    text-align: center;
    line-height: 60px;
  }

  .center_body /deep/ #tab-mail {
    background-color: #3743D6;
    color: #fff;
    font-size: 18px;
  }

  .center_body /deep/ .el-table__header-wrapper tr th {
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(68, 68, 68, 1);
    line-height: 24px;
    height: 60px;
    background-color: #eee;
  }

  .center_body /deep/ .el-table__body tr td {
    text-align: center;
    height: 60px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
    background-color: rgba(242, 249, 255, 1);
  }

  .center_body /deep/ .el-table__row {
    height: 60px;
  }

  .center_body /deep/ .el-table {
    margin: 20 auto;
  }

  .body {
    width: 90%;
    margin: 0 auto
  }

  .readed {
    color: #009900;
    cursor: pointer;
    display: inline-block;
    height: 100%;
    width: 100%;
    line-height: 50px;
    text-align: center;
  }

  ;

  .unread {
    color: #FF0000;
    cursor: pointer;
    display: inline-block;
    height: 100%;
    width: 100%;
    line-height: 50px;
    text-align: center;
  }

  ;

  .lab {
    width: 86px;
    height: 17px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
    display: inline-block
  }

  .top {
    height: 40px;
    margin: 30px 0 25px 50px;
  }

  // .center_body /deep/ .el-tooltip{height: 55px;}
  .batch_recharge {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    color: #000;
    border-bottom: 1px solid #ccc;
    font-family: Microsoft YaHei;

    >span {
      color: #3743D6;
      cursor: pointer;
      display: inline-block;
      width: auto;
      margin: 0 5px;
      font-size: 18px
    }

    >i {
      font-style: normal;
      color: #3743D6;
      font-size: 14px
    }
  }

  .fy {
    text-align: center;
    margin-top: 30px;
  }

  .title {
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    color: #000;
    padding-left: 20px;
    border-bottom: 1px solid #ccc;
  }

  .theme {
    display: inline-block;
    height: 100%;
    width: 100%;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }

  .d_title {
    height: 24px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 550;
    color: rgba(68, 68, 68, 1);
    line-height: 24px;
    display: block
  }

  .d_time {
    height: 50px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: rgba(68, 68, 68, 1);
    line-height: 50px;
    display: block
  }

  .d_content {
    width: 960px;
    margin: 60px 0 0 60px;
    height: 600px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    letter-spacing: 2px;
    overflow-y: auto;
  }

  .center_body /deep/ .el-checkbox__inner {
    border: 1px solid #000
  }

  .center_body /deep/ .is-checked .el-checkbox__inner {
    border: 1px solid #ccc
  }
}
</style>